@font-face {
    font-family: 'HairLarge';
    src: url('./assets/hairLarge.ttf');
}

body {
    margin: 0;
    font-family: 'HairLarge', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    direction: rtl;
    font-size: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
    min-height: 100vh;
}

#root {
    min-height: 100vh;
    height: 100%;
}

p {
    margin-block-start: .7em;
    margin-block-end: .7em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

a, a:hover, a:active, a:visited {
    color: #61dafb;
    text-decoration: none;
}

.blink {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}


.mobileOnly {
    display: block;
    height: 100%;
    width: 100%;
}

.mobileOnlyMessage {
    display: none;
}

@media (min-width: 820px) {
    .mobileOnly {
        display: none;
    }

    .mobileOnlyMessage {
        display: block;
    }
}
